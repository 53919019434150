var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice-view"},[(_vm.isLoading)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-progress-linear',{attrs:{"indeterminate":true}})],1)],1):_c('div',[_c('v-card',{attrs:{"flat":""}},[(_vm.Inventory)?_c('ReportViewBar',{attrs:{"title":(_vm.selectedStatus + " serial stock")}}):_vm._e(),_c('div',{staticClass:"text-right"},[_c('v-checkbox',{staticClass:"no-print",attrs:{"label":"Hide Cost Price"},model:{value:(_vm.hideCost),callback:function ($$v) {_vm.hideCost=$$v},expression:"hideCost"}})],1),_c('div',{staticClass:"ml-5"},[_vm._v(" Status "),_c('v-chip-group',{attrs:{"mandatory":"","active-class":"primary--text"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('v-chip',[_vm._v(" Available ")]),_c('v-chip',[_vm._v(" On Hold ")]),_c('v-chip',[_vm._v(" Repair ")])],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","prepend-inner-icon":"search","rounded":"","autofocus":"","dense":"","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('table',{staticClass:"center"},[_c('tbody',{staticClass:"report-container"},[_c('v-card-text',{staticClass:"pt-0"},[_c('v-data-table',{attrs:{"loading":_vm.localLoading,"dense":"","sort-by":"name","headers":_vm.headers,"expanded":_vm.expanded,"show-expand":"","itemsPerPage":_vm.filteredProducts.length,"hide-default-footer":"","items":_vm.filteredProducts},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('tr',[_c('td',{staticClass:"text-left"},[(!isExpanded)?_c('v-btn',{staticClass:"no-print",attrs:{"icon":""},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(isExpanded)?_c('v-btn',{staticClass:"no-print",attrs:{"icon":""},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()],1),_c('td',{staticClass:"text-left text-capitalize"},[_vm._v(_vm._s(item.localId))]),_c('td',{staticClass:"text-left text-capitalize"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.serialCount))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currency")(item.price)))]),(!_vm.hideCost)?_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currency")(item.totalCost / item.serialCount)))]):_vm._e(),(!_vm.hideCost)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.totalCost))+" ")]):_vm._e()])]}},(_vm.filteredProducts.length > 0 && !_vm.hideCost)?{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',{staticClass:"text-right font-weight-bold",attrs:{"colspan":headers.length - 2}},[_vm._v(" Total ")]),_c('td',{staticClass:"text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.totalQty)+" ")]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.total))+" ")])])]}}:null,{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"color":"#F3F8FC","flat":""}},[_c('v-card-text',{staticClass:"title"},[_c('PurchaseDetailList',{attrs:{"ProductId":item.id,"status":_vm.selectedStatus}})],1)],1)],1)]}}],null,true)})],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }