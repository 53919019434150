<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar v-if="Inventory" :title="`${selectedStatus} serial stock`" />
        <div class="text-right">
          <v-checkbox label="Hide Cost Price" v-model="hideCost" class="no-print"></v-checkbox>
        </div>
        <div class="ml-5">
          Status
          <v-chip-group v-model="status" mandatory active-class="primary--text">
            <v-chip> Available </v-chip>
            <!-- <v-chip> Sold </v-chip> -->
            <v-chip> On Hold </v-chip>
            <v-chip> Repair </v-chip>
          </v-chip-group>
        </div>
        <v-container fluid>
          <v-text-field class="mb-3" v-model="search" outlined prepend-inner-icon="search" rounded autofocus dense
            label="Search" single-line hide-details clearable></v-text-field>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table :loading="localLoading" dense sort-by="name" :headers="headers" :expanded.sync="expanded"
                  show-expand :itemsPerPage="filteredProducts.length" hide-default-footer :items="filteredProducts">
                  <template v-slot:item="{ item, isExpanded, expand }">
                    <tr>
                      <td class="text-left">
                        <v-btn class="no-print" @click="expand(true)" icon v-if="!isExpanded">
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                        <v-btn class="no-print" @click="expand(false)" icon v-if="isExpanded">
                          <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>
                      </td>
                      <td class="text-left text-capitalize">{{ item.localId }}</td>
                      <td class="text-left text-capitalize">{{ item.name }}</td>
                      <td class="text-center">{{ item.serialCount }}</td>
                      <td class="text-right">{{ item.price | currency }}</td>
                      <td class="text-right" v-if="!hideCost">{{ item.totalCost / item.serialCount | currency }}</td>
                      <td class="text-right" v-if="!hideCost">
                        {{ item.totalCost | currency }}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:body.append="{ headers }" v-if="filteredProducts.length > 0 && !hideCost">
                    <tr>
                      <td :colspan="headers.length - 2" class="text-right font-weight-bold">
                        Total
                      </td>
                      <td class="text-center font-weight-bold">
                        {{ totalQty }}
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ total | currency }}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-0">
                      <v-card color="#F3F8FC" flat>
                        <v-card-text class="title">
                          <PurchaseDetailList :ProductId="item.id" :status="selectedStatus"></PurchaseDetailList>
                        </v-card-text>
                      </v-card>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
import PurchaseDetailList from "@/modules/Inventory/Purchase/components/PurchaseDetailList";
import reportService from "@/modules/ReportView/service.js";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      expanded: [],
      localLoading: false,
      status: 0,
      search: "",
      showEmailBtn: false,
      total: 0,
      isLoading: true,
      Inventory: null,
      hideCost: false,
      headers: [
        {
          text: "ID",
          align: "left",
          value: "localId",
          sortable: true,
        },
        {
          text: "PRODUCT NAME",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "STOCK",
          align: "center",
          value: "serialCount",
          sortable: true,
        },
        {
          text: "SALE PRICE",
          align: "center",
          value: "price",
          sortable: true,
        },
        {
          text: "AVG UNIT COST",
          align: "center",
          value: "serialCount",
          sortable: true,
        },
        {
          text: "TOTAL COST",
          align: "right",
          value: "totalCost",
          sortable: true,
        },],
      totalQty: 0,
    };
  },
  watch: {
    status() {
      this.loadData();
    },
    hideCost(val) {
      if (!val) {
        this.headers = [
          {
            text: "PRODUCT NAME",
            align: "left",
            value: "name",
            sortable: true,
          },
          {
            text: "STOCK",
            align: "center",
            value: "serialCount",
            sortable: true,
          },
          {
            text: "UNIT RATE",
            align: "center",
            value: "serialCount",
            sortable: true,
          },
          {
            text: "TOTAL COST",
            align: "right",
            value: "totalCost",
            sortable: true,
          },
        ]
      } else {
        this.headers = [
          {
            text: "PRODUCT NAME",
            align: "left",
            value: "name",
            sortable: true,
          },
          {
            text: "STOCK",
            align: "center",
            value: "serialCount",
            sortable: true,
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    selectedStatus() {
      if (this.status === 0) {
        return "available";
        // } else if (this.status === 1) {
        //   return "sold";
      } else if (this.status === 1) {
        return "onHold";
      } else if (this.status === 2)
        return "repair";
      return null
    },
    filteredProducts() {
      if (!this.search) {
        return this.Inventory; // Return all products if the search query is empty
      }

      const searchTerms = this.search.toLowerCase().split(' ');

      return this.Inventory.filter(product => {
        return searchTerms.every(term =>
          (product.name && product.name.toLowerCase().includes(term)) || // Check product name
          (product.localId && product.localId.toString().toLowerCase().includes(term)) // Check localId safely
        );
      });
    },
  },
  created() {
    this.loadData();
    if (!this.currentBusiness) this.initUser();
  },
  components: {
    ReportViewBar,
    PurchaseDetailList
  },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    loadData() {
      this.localLoading = true
      reportService.getAvailableSerial({ status: this.selectedStatus }).then((response) => {
        // console.log('response', response)
        this.$Progress.finish();
        this.Inventory = response.data;
        // document.title = `Report - Available Inventory`;
        this.total = 0
        this.totalQty = 0

        this.Inventory.map((row) => {
          // console.log('row', row.serialCount)
          this.total += parseFloat(row.totalCost);
          this.totalQty += parseInt(row.serialCount);
        });
        this.isLoading = false;
        this.localLoading = false
      });
      return true;
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
